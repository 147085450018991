import { defineStore } from "pinia";

export const useAppStore = defineStore("appData", {
  state: () => ({
    auth: {
      user: {
        account: undefined,
        name: undefined,
        user_type: undefined,
        plan_type: undefined,
        role: [],
        started_at: undefined,
        expried_at: undefined,
        created_at: undefined,
      },
      isAuth: false,
    },
    num:{
      mode: 0,
      birthDate: undefined,
      firstName: undefined,
      lastName: undefined,
      nationality: undefined,
      input: {
        birthDate: undefined,
        lastNameAlpha: undefined,
        firstNameAlpha: undefined,
        infos: undefined,
        content:undefined,
        tone:undefined,
      },
      hash: undefined,
      channel: undefined,
      result:{
        key:undefined,
        message:undefined,
      },
    },
    wa:{
      mode: 0,
      birthDate: "",
      birthTime: "",
      placeCode: 0,
      input:{
        selectData:undefined,
        inputData:undefined,
        tone:undefined,
        modalityData:undefined,
        elementsData:undefined,
        planets:undefined,
        houses:undefined,
        aspects:undefined,
        chartsData:undefined,
      },
      hash: undefined,
      channel: undefined,
      result:{
        key:undefined,
        message:undefined,
      },
    },
    palm: {
      mode: 0,
      input:{
        content:undefined,
        tone:undefined,
        hand:undefined,
        select01:undefined,
        select02:undefined,
        select03:undefined,
        select04:undefined,
        select05:undefined,
        select06:undefined,
        select07:undefined,
      },
      hash: undefined,
      channel: undefined,
      result:{
        key:undefined,
        message:undefined,
      },
    },
    tq:{
      mode: 0,
      input:{
        content:undefined,
        tone:undefined,
        select:undefined,
        cardStyle:undefined,
        selectcards:undefined,
      },
      hash: undefined,
      channel: undefined,
      result:{
        key:undefined,
        message:undefined,
      },
    },
    review:{
      input:{
        age:undefined,
        personality:undefined,
        content:undefined,
        resultcontent:undefined,
      },
      hash: undefined,
      channel: undefined,
      result:{
        key:undefined,
        message:undefined,
      },
    },
    expression:{
      input:{
        age:undefined,
        personality:undefined,
        content:undefined,
        resultcontent:undefined,
      },
      hash: undefined,
      channel: undefined,
      result:{
        key:undefined,
        message:undefined,
      },
    },
    qaList:{}
  }),
  getters: {
  },
  actions: {
    async getQaList(){
      try {
          const response = await $fetch('/qa.json');
          this.qaList = response
      } catch (err) {
          console.error('Error fetching /qaList.json:', err);
          this.qaList = {}
      }
    },
    setResetAuth(){
      this.auth.user.account = undefined;
      this.auth.user.name = undefined;
      this.auth.user.user_type = undefined;
      this.auth.user.plan_type = undefined;
      this.auth.user.role = [];
      this.auth.user.started_at = undefined;
      this.auth.user.expried_at = undefined;
      this.auth.user.created_at = undefined;
      this.auth.isAuth = false;
    },
    setResetAll(){
      this.setResetnum();
      this.setResetwa();
      this.setResetpalm();
      this.setResettq();
      this.setResetReview();
      this.setResetExpression();
    },
    setResetnumPart(){
      this.num.input.birthDate = undefined;
      this.num.input.lastNameAlpha = undefined;
      this.num.input.firstNameAlpha = undefined;
      this.num.input.infos = undefined;
      this.num.input.content = undefined;
      this.num.input.tone = undefined;
      this.num.hash = undefined;
      this.num.result.key = undefined;
      this.num.result.message = undefined;
    },
    setResetnum(){
      this.num.mode = 0
      this.num.birthDate = undefined;
      this.num.firstName = undefined;
      this.num.lastName = undefined;
      this.num.nationality = undefined;
      this.num.input.birthDate = undefined;
      this.num.input.lastNameAlpha = undefined;
      this.num.input.firstNameAlpha = undefined;
      this.num.input.infos = undefined;
      this.num.input.content = undefined;
      this.num.input.tone = undefined;
      this.num.hash = undefined;
      this.num.result.key = undefined;
      this.num.result.message = undefined;
    },
    setResetwaPart(){
      this.wa.input.selectData = undefined;
      this.wa.input.inputData = undefined;
      this.wa.input.tone = undefined;
      this.wa.input.modalityData = undefined;
      this.wa.input.elementsData = undefined;
      this.wa.input.planets = undefined;
      this.wa.input.houses = undefined;
      this.wa.input.aspects = undefined;
      this.wa.input.chartsData = undefined;
      this.wa.hash = undefined;
      this.wa.result.key = undefined;
      this.wa.result.message = undefined;
    },
    setResetwa(){
      this.wa.mode = 0
      this.wa.birthDate = "";
      this.wa.birthTime = "";
      this.wa.placeCode = 0;
      this.wa.input.selectData = undefined;
      this.wa.input.inputData = undefined;
      this.wa.input.tone = undefined;
      this.wa.input.modalityData = undefined;
      this.wa.input.elementsData = undefined;
      this.wa.input.planets = undefined;
      this.wa.input.houses = undefined;
      this.wa.input.aspects = undefined;
      this.wa.input.chartsData = undefined;
      this.wa.hash = undefined;
      this.wa.result.key = undefined;
      this.wa.result.message = undefined;
    },
    setResetpalm(){
      this.palm.mode = 0;
      this.palm.input.content = undefined;
      this.palm.input.tone = undefined;
      this.palm.input.hand = undefined;
      this.palm.input.select01 = undefined;
      this.palm.input.select02 = undefined;
      this.palm.input.select03 = undefined;
      this.palm.input.select04 = undefined;
      this.palm.input.select05 = undefined;
      this.palm.input.select06 = undefined;
      this.palm.input.select07 = undefined;
      this.palm.hash = undefined;
      this.palm.result.key = undefined;
      this.palm.result.message = undefined;
    },
    setResettq(){
      this.tq.mode = 0;
      this.tq.input.content = undefined;
      this.tq.input.tone = undefined;
      this.tq.input.select = undefined;
      this.tq.input.cardStyle = undefined;
      this.tq.input.selectcards = undefined;
      this.tq.hash = undefined;
      this.tq.result.key = undefined;
      this.tq.result.message = undefined;
    },
    setResetReview(){
      this.review.input.age = undefined;
      this.review.input.personality = undefined;
      this.review.input.content = undefined;
      this.review.input.resultcontent = undefined;
      this.review.hash = undefined;
      this.review.result.key = undefined;
      this.review.result.message = undefined;
    },
    setResetExpression(){
      this.expression.input.age = undefined;
      this.expression.input.personality = undefined;
      this.expression.input.content = undefined;
      this.expression.input.resultcontent = undefined;
      this.expression.hash = undefined;
      this.expression.result.key = undefined;
      this.expression.result.message = undefined;
    }
  },
  persist: {
    storage: persistedState.localStorage,
    enabled: true,
  },
});