<template>
  <div class="zoey">
    <CommonHeader :is-top="true" ></CommonHeader>
    <slot />
  </div>
  
</template>
<script setup>

</script>
<style lang="scss"  scoped>
.zoey{
  color: $zoey-white;
  padding-right: calc(env(safe-area-inset-right) + 22px);
  padding-left: calc(env(safe-area-inset-left) + 22px);
  padding-top: 22px;
  padding-bottom: 22px;
}

</style>
