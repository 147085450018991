<template>
  <div>
    <ClientOnly>
    <NuxtLayout>
    <NuxtPage :key="$route.fullPath"></NuxtPage>
    <Transition>
      <CommonMenu v-if="open" @closeMenu="closeMenu"></CommonMenu>
    </Transition>
    </NuxtLayout>
    </ClientOnly>
  </div>
</template>
<script setup>
provide('openMenu',openMenu)
const open = ref(false)
function openMenu(){
  open.value = open.value ? false : true;
}

function closeMenu(){
  open.value = open.value ? false : true;
}
</script>
<style>
.page-enter-active, .page-leave-active {
  transition: opacity 0.2s;
}
.page-enter-from, .page-leave-to {
  opacity: 0;
}

.layout-enter-active,
.layout-leave-active {
  transition: opacity 0.3s;
}
.layout-enter-from,
.layout-leave-to {
  opacity: 0;
}
</style>
