<template>
  <div class="zoey">
    <slot />
  </div>
  
</template>
<script setup>
</script>
<style lang="scss" scoped>
.zoey{
  background-image: url('~/assets/img/common/content/content-bg.png'), linear-gradient($primary-color, $primary-color);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 5px;
  color: $zoey-black;
}

</style>
