<template>
  <div class="menu">
      <a class="close" @click="closeMenu">
          <img src="~/assets/img/common/btn-close-menu.svg"/>
      </a>
      
      <div class="list">
        <img class="menu-logo" src="~/assets/img/common/LOGO_HOME.svg"/>
          <div class="section-top">
              <ul>
                  <li class="menu-line-top"><a @click="moveto('/')" class="menu-top-item">
                    <v-icon icon="mdi-chevron-right" color="#fff" ></v-icon>
                    トップ
                  </a></li>
                  <li class="menu-line-top"><a @click="moveto('/category')" class="menu-top-item">
                    <v-icon icon="mdi-chevron-right" color="#fff" ></v-icon>
                    カテゴリー
                  </a></li>
                  <li><a @click="moveto('/my')" class="menu-top-item">
                    <v-icon icon="mdi-chevron-right" color="#fff" ></v-icon>
                    マイページ
                  </a></li>
              </ul>
          </div>
          <div class="section-footer">
              <ul>
                  <li class="menu-line"><NuxtLink @click="moveto('/credits?callback=my')" >
                    <v-icon icon="mdi-chevron-right" color="#fff" ></v-icon>
                  クレジットの追加
                  </NuxtLink></li>
                  <li class="menu-line"><NuxtLink to="https://school.heartf.com/index.php?FortuneTellerTrainingApp#top" target="_blank" >
                    <v-icon icon="mdi-chevron-right" color="#fff" ></v-icon>
                  使い方の説明
                  </NuxtLink></li>
                  <li class="menu-line"><NuxtLink to="https://school.heartf.com/onlinelp/" target="_blank">
                    <v-icon icon="mdi-chevron-right" color="#fff" ></v-icon>
                  オンラインスクール
                  </NuxtLink></li>
                  <li class="menu-line"><NuxtLink to="https://school.heartf.com/" target="_blank" >
                    <v-icon icon="mdi-chevron-right" color="#fff" ></v-icon>
                  運営会社
                  </NuxtLink></li>
              </ul>
          </div>
      </div>
  </div>
</template>
<script setup>
const emits = defineEmits();

const router = useRouter();
function moveto(url){
    // router.push(url);
    navigateTo(url);
    closeMenu();
}
function closeMenu(){
    emits('closeMenu');
}
</script>
<style lang="scss" scoped>
.menu{
    position: fixed;
    background-image:
    url('~/assets/img/common/header/header-left.png'),
    url('~/assets/img/common/header/header-right.png'),
    url('~/assets/img/common/menu-bg.png');
    background-size: 80px, 80px, cover;
    background-repeat: no-repeat, no-repeat, no-repeat;
    background-position: 
    3px calc(100% - 3px), 
    calc(100% - 3px) calc(100% - 3px),
    center center;
    height: 100dvh;
    width: 100%;
    
    top: 0px;
    left: 0px;
    z-index: 999;
    > .close{
        position: absolute;
        top: 24px;
        right: 24px;
        z-index: 1000;
    }
    
    > .list{
        height: calc(100dvh - 24px); 
        max-height: calc(100dvh - 24px); 
        width: 100dvw;
        overflow-y: auto;
        &::-webkit-scrollbar {
                display: none;
                -webkit-appearance: none;
                width: 0;
                height: 0;
                background: transparent;    
            }
        
        position: absolute;
        top: 12px;
        left: 0px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    > .menu-logo{
        position: relative;
        margin-top: 48px;
        width: 160px;
        height: 123px;
        justify-self: center;
        align-self: center;
    }

      > .section-top{
        padding: 30px 5px;
        position: relative;
        font-weight: 700;
        font-size: 30px;
        letter-spacing: 0.1em;
        line-height: 2.8;
        width: 100%;
        margin: 20px 0px;
        .menu-top-item{
            display: flex;
            align-items: center;
            gap:40px;
            margin-left: 36px;
        }
        background: 
        url('~/assets/img/common/header/header-border.png'),
        linear-gradient(180deg, rgba(0,0,0,0.15) 0%, rgba(0,0,0,0.15) 100%),
        url('~/assets/img/common/header/header-border.png');
        background-position: 
        center 5px, 
        center center,
        center calc(100% - 5px);
        background-repeat: 
        repeat-x,
        no-repeat,
        repeat-x;
        background-size: 
        600px,
        100% 100%,
        600px;
      }
     
      > .section-footer{
          margin-top: 20px;
          position: relative;
          font-weight: 700;
          font-size: 20px;
          line-height: 3.0;
          left: 28px;
          width: 90%;
      }

      & li{
          list-style: none;
          & a{
              text-decoration: none;
              color: white;
              display: flex;
              & img {
                  padding-right: 10px;
              }
              display: flex;
              align-items: center;
          }
      }
      .menu-line{
        border-bottom: 1px solid #ccc;
      }
      .menu-line-top{
        border-bottom: 1px solid rgba($color: #ccc, $alpha: 0.4);
      }
  }
}

//add @media (min-aspect-ratio: 1.0) to menu bckground image
@media (min-aspect-ratio: 1.0){
  .menu{
    background-image:
    url('~/assets/img/common/header/header-left.png'),
    url('~/assets/img/common/header/header-right.png'),
    url('~/assets/img/common/menu-bg-yoko.png');
    background-size: 80px, 80px, cover;
    background-repeat: no-repeat, no-repeat, no-repeat;
    background-position: 
    3px calc(100% - 3px), 
    calc(100% - 3px) calc(100% - 3px),
    center center;
    height: 100dvh;
    width: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 999;
  }
}
</style>