<template>
  <div class="zoey-header">
    <NuxtLink v-if="!isTop"  to="/"><img width="80" src="~/assets/img/common/logo/header-icon.png"></NuxtLink>
    <div v-else></div>
    <a @click="openMenu">
      <img src="~/assets/img/common/btn-menu-white.svg">
    </a>
  </div>
</template>

<script setup>

const props = defineProps({
  isTop:{
    type:Boolean,
    default:false,
  }
})
const openMenu_inject = inject('openMenu')
function openMenu(){
  openMenu_inject();
}


</script>
<style lang="scss" scoped>
.zoey{
  &-header{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 60px;
  }
}
</style>
